import PersonalizedLearningImage from "../../../../../images/personalized_learning.webp";
import CourseDesignImage from "../../../../../images/course_design.webp";
import AnalyticsImage from "../../../../../images/analytics.webp";
import ExerciseImage from "../../../../../images/exercise.webp";

export const helpList = [
    {
        id: 1,
        title: "website_help.item_1.title",
        image: PersonalizedLearningImage,
        content: "website_help.item_1.description",
    },
    {
        id: 2,
        title: "website_help.item_2.title",
        image: CourseDesignImage,
        content: "website_help.item_2.description",
    },
    {
        id: 3,
        title: "website_help.item_3.title",
        image: AnalyticsImage,
        content: "website_help.item_3.description",
    },
    {
        id: 4,
        title: "website_help.item_4.title",
        image: ExerciseImage,
        content: "website_help.item_4.description",
    }
];