import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18n
    .use(LanguageDetector) // detects the current language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translations: require('../../locale/en/translations.json')
            },
            tr: {
                translations: require('../../locale/tr/translations.json')
            }
        },
        lng: 'en',
        fallbackLng: 'en',
        ns: ['translations'],
        defaultNS: 'translations',
        detection: {
            checkWhitelist: true,
        },
        debug: false
    });

export default i18n;