import {Link} from 'react-router-dom'
import {useLayoutContext} from "../layoutProvider";
import {sidebarMenuColors} from "../../utils/constants";

let currentColor = "";

const AdminAssistantItem = (props) => {
    const {sidebarSelectedMenuItem, setSidebarSelectedMenuItem} = useLayoutContext();
    if (sidebarSelectedMenuItem === 'admin_assistants') {
        currentColor = sidebarMenuColors.selected;
    } else {
        currentColor = sidebarMenuColors.unselected;
    }

    return (
        <div className="is-scrollbar-hidden flex flex-col space-y-4 overflow-y-auto pt-6">
            <Link to="/assistants" onClick={(event) => setSidebarSelectedMenuItem('dashboard')}
                  data-tooltip="Dashboard"
                  data-placement="right"
                  className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path fill={currentColor} d="M18,22H6a3,3,0,0,1-3-3V7A3,3,0,0,1,6,4H18a3,3,0,0,1,3,3V19A3,3,0,0,1,18,22ZM6,6A1,1,0,0,0,5,7V19a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1Z"/>
                    <path fill={currentColor} d="M9,13a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0v2A1,1,0,0,1,9,13Z"/>
                    <path fill={currentColor} d="M15,13a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0v2A1,1,0,0,1,15,13Z"/>
                    <path fill={currentColor} d="M12,5a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V4A1,1,0,0,1,12,5Z"/>
                    <path fill={currentColor} d="M15,18H9a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z"/>

                </svg>
            </Link>
        </div>);
};

export default AdminAssistantItem;