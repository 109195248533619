import {Link} from 'react-router-dom'
import {useLayoutContext} from "../layoutProvider";
import {sidebarMenuColors} from "../../utils/constants";

let currentColor = "";

const AdminUserManagementItem = (props) => {
    const {sidebarSelectedMenuItem, setSidebarSelectedMenuItem} = useLayoutContext();
    if (sidebarSelectedMenuItem === 'admin_user_management') {
        currentColor = sidebarMenuColors.selected;
    } else {
        currentColor = sidebarMenuColors.unselected;
    }

    return (
        <div className="is-scrollbar-hidden course flex flex-col space-y-4 overflow-y-auto pt-6">
            <Link to="/admin/users" onClick={(event) => setSidebarSelectedMenuItem('chat_wizard_type')}
                  data-tooltip="Chat wizard"
                  data-placement="right"
                  className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 512 512"
                >
                    <path
                        fill={currentColor}
                        fillOpacity="1"
                        d="M512,396.063v63.141c0,5.888-4.768,10.666-10.666,10.666H240.993
	c-5.898,0-10.666-4.778-10.666-10.666V410.44h-29.587c-0.523,0.085-1.067,0.128-1.621,0.128H10.666
	C4.768,410.568,0,405.801,0,399.903v-63.141c0-31.635,25.182-57.489,56.55-58.608c0.352-0.032,0.704-0.053,1.067-0.053
	c23.145,0,42.279-17.513,44.86-39.986c-20.084-14.601-34.76-40.413-38.983-71.439c-0.768-0.864-1.419-1.867-1.888-2.997
	c-1.696-4.149-3.136-8.501-4.256-12.916c-11.21-43.922,9.525-88.281,46.215-98.882c3.669-1.056,7.477-1.76,11.37-2.09
	c10.41-5.088,21.417-7.658,32.765-7.658c43.89,0,79.588,38.418,79.588,85.636c0,12.276-2.368,24.137-7.029,35.24
	c-0.491,1.173-1.173,2.197-1.984,3.082c-2.656,20.158-9.802,38.791-20.82,53.702c-5.45,7.37-11.594,13.535-18.26,18.377
	c2.613,22.451,21.726,39.933,44.86,39.933c0.405,0,0.789,0.032,1.173,0.075c7.221,0.267,14.26,1.877,20.766,4.65
	c6.506,2.773,12.5,6.709,17.652,11.658l0.064-0.064c11.082,10.666,17.993,25.651,17.993,42.215v1.237
	c1.707-0.213,3.434-0.352,5.184-0.416c0.341-0.032,0.693-0.053,1.056-0.053c23.145,0,42.279-17.503,44.86-39.975
	c-4.48-3.242-8.735-7.05-12.671-11.412c-9.226-10.25-16.5-23.23-21.246-37.672c-1.216,1.707-2.325,3.456-3.178,4.864
	c-2.058,3.36-5.823,5.322-9.738,5.077c-3.936-0.235-7.423-2.624-9.066-6.208c-5.152-11.274-8.639-23.411-10.378-36.093
	c-0.651-4.416-1.045-9.066-1.205-14.1c-0.949-29.758,7.711-58.118,24.403-79.855c17.172-22.366,40.743-35.144,66.373-35.965
	c12.404-0.395,24.457,2.026,35.858,7.199c0.469-0.032,3.402-0.139,4.576-0.085c22.195,0.213,43.143,10.474,59.014,28.915
	c15.497,18.004,24.478,42.002,25.299,67.557c0.832,26.057-6.901,50.929-21.78,70.042c-2.058,2.645-5.184,4.117-8.415,4.117
	c-1.056,0-2.122-0.16-3.157-0.48c-3.424-1.056-6.026-3.744-7.05-7.071c-7.093,17.353-17.865,31.613-30.931,41.127
	c2.57,22.483,21.705,40.018,44.871,40.018c0.363,0,0.715,0.021,1.067,0.053C486.818,338.564,512,364.428,512,396.063z
	 M490.668,448.539v-52.476c0-20.585-16.745-37.33-37.33-37.33c-0.352,0-0.704-0.021-1.056-0.053
	c-3.669-0.117-7.263-0.512-10.772-1.205c-9.269,30.419-37.608,52.113-70.341,52.113c-32.744,0-61.083-21.694-70.351-52.113
	c-3.498,0.693-7.103,1.088-10.772,1.205c-0.352,0.032-0.704,0.053-1.056,0.053c-2.496,0-4.928,0.245-7.285,0.715
	c-8.501,1.685-15.967,6.271-21.332,12.671c-4.021,4.789-6.858,10.612-8.063,16.991c-0.427,2.25-0.651,4.576-0.651,6.954v14.378
	v38.098L490.668,448.539L490.668,448.539z M454.959,222.573c3.349-10.058,4.928-20.99,4.565-32.243
	c-0.661-20.702-7.818-39.997-20.148-54.331c-11.839-13.748-27.187-21.385-43.207-21.492c-0.16,0-5.632,0.256-5.834,0.256
	c-1.76,0.053-3.573-0.309-5.141-1.099c-14.025-7.071-27.784-6.303-28.414-6.282c-19.124,0.608-36.925,10.431-50.129,27.635
	c-13.695,17.844-20.798,41.341-20.009,66.181c0.139,4.234,0.469,8.095,1.003,11.796c0.587,4.309,1.419,8.522,2.464,12.628
	c8.938-9.61,23.145-17.62,31.603-20.809c8.565-3.189,17.556-4.949,26.782-5.248c1.877-0.053,3.786-0.053,5.664,0.011
	c12.522,0.437,23.785-5.504,29.363-15.487c1.973-3.53,3.701-7.274,5.12-11.103c1.003-2.73,3.093-4.928,5.76-6.09
	c2.666-1.152,5.696-1.173,8.373-0.043c21.182,8.906,38.514,25.875,48.817,47.783C452.826,217.241,453.946,219.886,454.959,222.573z
	 M428.007,216.974c0.032-0.331,0.075-0.64,0.128-0.96c-6.25-10.25-14.569-18.697-24.297-24.691
	c-0.544,1.067-1.109,2.133-1.696,3.178c-9.503,16.991-28.126,27.102-48.721,26.398c-1.408-0.053-2.837-0.053-4.245-0.011
	c-6.911,0.224-13.631,1.536-19.966,3.893c-4.512,1.707-8.81,3.936-12.863,6.645c7.317,33.736,29.662,57.606,54.769,57.606
	c13.588,0,26.803-7.018,37.234-19.764C419.389,255.787,426.375,237.207,428.007,216.974z M371.169,388.256
	c23.689,0,44.156-16.009,50.321-38.269c-15.785-9.023-27.539-24.329-31.816-42.492c-5.962,1.867-12.17,2.869-18.558,2.869
	c-6.261,0-12.458-0.992-18.462-2.869c-4.277,18.164-16.02,33.469-31.816,42.492C327.002,372.246,347.47,388.256,371.169,388.256z
	 M260.373,344.878v-8.245c0-7.605-2.293-14.687-6.218-20.596c-0.235-0.256-0.427-0.512-0.629-0.789
	c-6.997-9.898-18.409-15.817-30.515-15.817c-0.352,0-0.704-0.021-1.056-0.053c-7.679-0.235-15.039-1.781-21.854-4.426
	l-50.502,72.879c-1.984,2.88-5.269,4.597-8.767,4.597c-3.498,0-6.773-1.717-8.767-4.597l-50.492-72.879
	c-6.815,2.645-14.175,4.192-21.854,4.426c-0.352,0.032-0.704,0.053-1.056,0.053c-20.585,0-37.33,16.745-37.33,37.33v52.476h61.254
	v-0.128h148.148C232.994,370.103,244.374,353.848,260.373,344.878z M182.865,117.889c8.65,0,16.628,2.88,23.038,7.733
	c-1.024-34.472-26.728-62.16-58.203-62.16c-8.618,0-17.012,2.112-24.937,6.271c-1.387,0.725-2.912,1.141-4.48,1.216
	c-3.04,0.139-5.994,0.619-8.799,1.429c-24.275,7.007-38.205,36.594-32.669,67.514c6.901-8.266,11.988-17.993,14.708-28.446
	c1.365-5.258,5.92-9.023,11.338-9.365c5.376-0.341,10.367,2.794,12.383,7.807c0.875,2.176,2.325,4.064,4.224,5.482
	c2.208,1.643,4.842,2.517,7.626,2.517C127.093,117.889,182.865,117.889,182.865,117.889z M180.294,207.119
	c11.423-15.465,17.737-36.168,17.812-58.32c-2.752-5.653-8.533-9.578-15.241-9.578h-55.771c-7.413,0-14.463-2.336-20.393-6.762
	c-0.032-0.021-0.064-0.053-0.107-0.075c-5.269,11.434-12.799,21.78-22.121,30.323c2.314,18.27,9.002,34.898,19.124,47.26
	c10.431,12.746,23.657,19.764,37.234,19.764C155.507,229.73,169.522,221.699,180.294,207.119z M140.831,343.033l40.829-58.918
	c-10.943-9.077-18.921-21.598-22.302-35.869c-5.984,1.856-12.191,2.816-18.526,2.816c-6.367,0-12.564-0.992-18.505-2.848
	c-3.381,14.292-11.359,26.814-22.313,35.901L140.831,343.033z"
                    />
                    <path
                        fill={currentColor}
                        fillOpacity="0.3"
                        d="M490.668,396.063v52.476H251.659V410.44v-14.377c0-2.378,0.224-4.704,0.651-6.954
	c1.205-6.378,4.042-12.202,8.063-16.991c5.365-6.399,12.831-10.986,21.332-12.671c2.357-0.469,4.789-0.715,7.285-0.715
	c0.352,0,0.704-0.021,1.056-0.053c3.669-0.117,7.274-0.512,10.772-1.205c9.269,30.419,37.608,52.113,70.351,52.113
	c32.733,0,61.072-21.694,70.341-52.113c3.509,0.693,7.103,1.088,10.772,1.205c0.352,0.032,0.704,0.053,1.056,0.053
	C473.923,358.733,490.668,375.478,490.668,396.063z"
                    />
                    <path
                        fill={currentColor}
                        fillOpacity="0.6"
                        d="M459.524,190.331c0.363,11.252-1.216,22.185-4.565,32.243c-1.013-2.688-2.133-5.333-3.37-7.935
	c-10.303-21.908-27.635-38.877-48.817-47.783c-2.677-1.131-5.706-1.109-8.373,0.043c-2.666,1.163-4.757,3.36-5.76,6.09
	c-1.419,3.829-3.146,7.573-5.12,11.103c-5.578,9.983-16.841,15.924-29.363,15.487c-1.877-0.064-3.786-0.064-5.664-0.011
	c-9.226,0.299-18.217,2.058-26.782,5.248c-8.458,3.189-22.665,11.199-31.603,20.809c-1.045-4.106-1.877-8.319-2.464-12.628
	c-0.533-3.701-0.864-7.562-1.003-11.796c-0.789-24.841,6.314-48.337,20.009-66.181c13.204-17.204,31.005-27.027,50.129-27.635
	c0.629-0.021,14.388-0.789,28.414,6.282c1.568,0.789,3.381,1.152,5.141,1.099c0.203,0,5.674-0.256,5.834-0.256
	c16.02,0.107,31.368,7.743,43.207,21.492C451.706,150.334,458.863,169.629,459.524,190.331z"
                    />
                    <g>
                        <path
                            fill={currentColor}
                            fillOpacity="0"
                            d="M428.135,216.014c-0.053,0.32-0.096,0.629-0.128,0.96c-1.632,20.233-8.618,38.813-19.657,52.294
		c-10.431,12.746-23.646,19.764-37.234,19.764c-25.107,0-47.452-23.87-54.769-57.606c4.053-2.709,8.351-4.938,12.863-6.645
		c6.335-2.357,13.055-3.669,19.966-3.893c1.408-0.043,2.837-0.043,4.245,0.011c20.596,0.704,39.218-9.407,48.721-26.398
		c0.587-1.045,1.152-2.112,1.696-3.178C413.566,197.317,421.885,205.764,428.135,216.014z"
                        />
                        <path
                            fill={currentColor}
                            fillOpacity="0"
                            d="M421.49,349.987c-6.165,22.259-26.632,38.269-50.321,38.269c-23.699,0-44.167-16.009-50.332-38.269
		c15.796-9.023,27.539-24.329,31.816-42.492c6.005,1.877,12.202,2.869,18.462,2.869c6.389,0,12.596-1.003,18.558-2.869
		C393.951,325.658,405.705,340.964,421.49,349.987z"
                        />
                    </g>
                    <path
                        fill={currentColor}
                        fillOpacity="0.3"
                        d="M260.373,336.633v8.245c-15.999,8.97-27.379,25.225-29.64,44.231H82.585v0.128H21.332v-52.476
	c0-20.585,16.745-37.33,37.33-37.33c0.352,0,0.704-0.021,1.056-0.053c7.679-0.235,15.039-1.781,21.854-4.426l50.492,72.879
	c1.994,2.88,5.269,4.597,8.767,4.597c3.498,0,6.783-1.717,8.767-4.597l50.502-72.879c6.815,2.645,14.175,4.192,21.854,4.426
	c0.352,0.032,0.704,0.053,1.056,0.053c12.106,0,23.518,5.919,30.515,15.817c0.203,0.277,0.395,0.533,0.629,0.789
	C258.08,321.947,260.373,329.029,260.373,336.633z"
                    />
                    <path
                        fill={currentColor}
                        fillOpacity="0.6"
                        d="M205.903,125.622c-6.41-4.853-14.388-7.733-23.038-7.733h-55.771c-2.784,0-5.418-0.875-7.626-2.517
	c-1.899-1.419-3.349-3.306-4.224-5.482c-2.016-5.013-7.007-8.149-12.383-7.807c-5.418,0.341-9.973,4.106-11.338,9.365
	c-2.72,10.452-7.807,20.18-14.708,28.446c-5.536-30.92,8.394-60.507,32.669-67.514c2.805-0.811,5.76-1.291,8.799-1.429
	c1.568-0.075,3.093-0.491,4.48-1.216c7.925-4.16,16.319-6.271,24.937-6.271C179.174,63.461,204.879,91.15,205.903,125.622z"
                    />
                    <g>
                        <path
                            fill={currentColor}
                            fillOpacity="0"
                            d="M198.106,148.798c-0.075,22.153-6.389,42.855-17.812,58.32
		c-10.772,14.58-24.787,22.611-39.463,22.611c-13.578,0-26.803-7.018-37.234-19.764c-10.122-12.362-16.809-28.99-19.124-47.26
		c9.322-8.543,16.852-18.889,22.121-30.323c0.043,0.021,0.075,0.053,0.107,0.075c5.93,4.426,12.98,6.762,20.393,6.762h55.771
		C189.574,139.22,195.354,143.145,198.106,148.798z"
                        />
                        <path
                            fill={currentColor}
                            fillOpacity="0"
                            d="M181.66,284.115l-40.829,58.918l-40.818-58.918c10.954-9.087,18.932-21.609,22.313-35.901
		c5.941,1.856,12.138,2.848,18.505,2.848c6.335,0,12.543-0.96,18.526-2.816C162.738,262.517,170.716,275.038,181.66,284.115z"
                        />
                    </g>
                </svg>
            </Link>
        </div>);
};

export default AdminUserManagementItem;