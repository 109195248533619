import React, {useEffect, useState} from "react";

import {useLayoutContext} from "../../../layout/layoutProvider";
import {Link, useLocation} from "react-router-dom";
import {openCloseSidebarPanel} from "../../../utils/helpers";
import FetchClient from "../../../utils/fetchClient";
import {editButtonIcon} from "../../../utils/svgs";
import Dialog from "@mui/material/Dialog";
import Select from "react-select";
import {CustomStyles, Option} from "../../../components/selectOptions";

const AdminUserManagementPage = () => {
    const location = useLocation()
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();

    const [users, setUsers] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(1);

    const [selectedUser, setSelectedUser] = useState({id: -1, email: "", name: "", is_active: false, user_role: ""});
    const [openEditUserModal, setOpenEditUserModal] = useState(false);

    function updateMenu() {
        if (location.pathname.includes('admin/users')) {
            setSidebarSelectedMenuItem('admin_user_management');
        }
    }

    const handleChangeItemsPerPage = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };
    const getUsers = (currentPage) => {
        FetchClient({
            method: 'get',
            url: `admin/users/${currentPage - 1}`
        }).then(response => {
            setUsers(response?.data?.items);
            setTotalUsers(response?.data?.total);

            setPageNumbers([]);
            for (let i = 1; i <= Math.ceil(response?.data?.total / itemsPerPage); i++) {
                setPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
            }
        })
    }

    const editUserForm = (user) => {
        setOpenEditUserModal(true);
        setSelectedUser({
            id: user.id, email: user.email, name: user.name, is_active: user.is_active, user_role: user.user_role
        });
    };
    const handleCloseEditUser = () => {
        setOpenEditUserModal(false);
        setSelectedUser({
            id: -1, email: "", name: "", is_active: false, user_role: ""
        });
    }
    const handleEditUserChange = (event) => {
        const {name, value} = event.target;
        setSelectedUser(values => ({...values, [name]: value}));
    }
    const editUser = async () => {
        FetchClient({
            method: 'put',
            url: `admin/user/update`,
            data: {
                id: selectedUser.id,
                email: selectedUser.email,
                name: selectedUser.name,
                user_role: selectedUser.user_role,
                is_active: selectedUser.is_active
            }
        }).then(response => {
            getUsers(currentPage);
        });
    }

    const handleFilterUserChange = (event) => {
        const {name, value} = event.target;
        setSelectedUser(values => ({...values, [name]: value}));
    }


    useEffect(() => {
        updateMenu();
        openCloseSidebarPanel(false);
    }, [location]);

    useEffect(() => {
        getUsers(currentPage);
    }, [currentPage]);

    const userItems = users.map((user, index) => (
        <tr key={user.id} className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
            <td
                className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5"
            >
                <Link
                    to={"/user_list/user/" + user.id + "/chat/" + user.id}
                    data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                    data-active-className="font-medium text-primary dark:text-accent-light"
                    className={"flex-1 nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                        + (user.active ? " font-bold" : "")}
                >
                    {user.id}
                </Link>
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                {user.name}
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                {user.email}
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                <div
                    className={"badge rounded-full " + (user.user_role === "student" ? "bg-secondary/10 text-secondary dark:bg-secondary-light/15 dark:text-secondary-light" : "bg-info/10 text-info dark:bg-info/15 dark:text-info")}>
                    {user.user_role}
                </div>
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                {(user.usage_prompt_tokens + user.usage_completion_tokens).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                <div
                    className={"badge rounded-full " + (user.is_active === true ? "bg-success/10 text-success dark:bg-success/15 dark:text-success" : "bg-error/10 text-error dark:bg-error/15 dark:text-error")}>
                    {user.is_active === true ? "active" : "inactive"}
                </div>
            </td>
            <td className="px-4 py-3 sm:px-5">
                {new Date(user.creation_date).toLocaleString(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                })}
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                <div className="h-8 w-8 cursor-pointer" onClick={() => editUserForm(user)}>
                    {editButtonIcon("h-6 w-6", "0 0 24 24")}
                </div>
            </td>
        </tr>)
    );

    return (
        <main className="main-content h-100vh mt-0">
            <div className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] mt-20 transition-all duration-[.25s]">
                <div className="items-center space-x-4 py-5 lg:py-6">
                    <div className="mt-12 mr-4 ml-4">
                        <div className="col-span-12 lg:col-span-8 xl:col-span-9">
                            <div className="flex items-center justify-between">
                                <h2
                                    className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100"
                                >
                                    User List
                                </h2>
                                <div className="flex">
                                    <div className="table-search-wrapper flex items-center">
                                        <label className="block">
                                            <input
                                                className="table-search-input form-input w-0 bg-transparent px-1 text-right transition-all duration-100 placeholder:text-slate-500 dark:placeholder:text-navy-200"
                                                placeholder="Search here..."
                                                type="text"
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                        </label>
                                        <button
                                            className="table-search-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4.5 w-4.5"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 card" id="user_table_filters">
                                <div className="max-w-3xl py-3">
                                    <div
                                        className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:gap-6"
                                    >
                                        <label className="block">
                                            <span>Name contains:</span>
                                            <div className="relative mt-1.5 flex">
                                                <input
                                                    className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                    placeholder="Name"
                                                    type="text"
                                                />
                                            </div>
                                        </label>
                                        <label className="block">
                                            <span>Email contains</span>
                                            <div className="relative mt-1.5 flex">
                                                <input
                                                    className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                    placeholder="Email"
                                                    type="text"
                                                />
                                            </div>
                                        </label>
                                        <div className="sm:col-span-2">
                                            <span>User Roles:</span>
                                            <div
                                                className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-5 lg:gap-6"
                                            >
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:border-secondary checked:bg-secondary hover:border-secondary focus:border-secondary dark:border-navy-400 dark:checked:border-secondary-light dark:checked:bg-secondary-light dark:hover:border-secondary-light dark:focus:border-secondary-light"
                                                        type="checkbox"
                                                    />
                                                    <span>Student</span>
                                                </label>
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent"
                                                        type="checkbox"
                                                    />
                                                    <span>Teacher</span>
                                                </label>
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:!border-success checked:bg-success hover:!border-success focus:!border-success dark:border-navy-400"
                                                        type="checkbox"
                                                    />
                                                    <span>Admin</span>
                                                </label>
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:!border-error checked:bg-error hover:!border-error focus:!border-error dark:border-navy-400"
                                                        type="checkbox"/>
                                                    <span>Assistant</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <span>User Status:</span>
                                            <div
                                                className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-5 lg:gap-6"
                                            >
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:!border-success checked:bg-success hover:!border-success focus:!border-success dark:border-navy-400"
                                                        type="checkbox"
                                                    />
                                                    <span>Active</span>
                                                </label>
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:!border-error checked:bg-error hover:!border-error focus:!border-error dark:border-navy-400"
                                                        type="checkbox"/>
                                                    <span>Inactive</span>
                                                </label>
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:border-secondary checked:bg-secondary hover:border-secondary focus:border-secondary dark:border-navy-400 dark:checked:border-secondary-light dark:checked:bg-secondary-light dark:hover:border-secondary-light dark:focus:border-secondary-light"
                                                        type="checkbox"/>
                                                    <span>Teacher Request</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 space-x-1 text-center">
                                    <button
                                        className="btn font-medium text-slate-700 hover:bg-slate-300/20
                                                        active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20
                                                        dark:active:bg-navy-300/25">
                                    Cancel
                                    </button>

                                    <button
                                        className="btn bg-primary font-medium text-white hover:bg-primary-focus
                                                        focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent
                                                        dark:hover:bg-accent-focus dark:focus:bg-accent-focus
                                                        dark:active:bg-accent/90">
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                                    <table className="is-hoverable w-full text-left">
                                        <thead>
                                        <tr>
                                            <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                ID
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Name
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Email
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                User Role
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Usage
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Active
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Creation Date
                                            </th>
                                            <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Actions
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {userItems}
                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5"
                                >
                                    <div className="flex items-center space-x-2 text-xs+">
                                        <span>Show</span>
                                        <label className="block">
                                            <select value={itemsPerPage}
                                                    onChange={(e) => handleChangeItemsPerPage(parseInt(e.target.value))}
                                                    className="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
                                                <option>10</option>
                                                <option>20</option>
                                                <option>50</option>
                                                <option>100</option>
                                            </select>
                                        </label>
                                        <span>entries</span>
                                    </div>

                                    <ol className="pagination">
                                        <li className="rounded-l-lg bg-slate-150 dark:bg-navy-500">
                                            <a
                                                href="#"
                                                onClick={(event) => (currentPage > 1 ?
                                                    setCurrentPage((prev) => prev - 1) : event.preventDefault())}
                                                className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 19l-7-7 7-7"
                                                    />
                                                </svg>
                                            </a>
                                        </li>
                                        {pageNumbers.map((page, index) => (
                                            <li key={index} className="bg-slate-150 dark:bg-navy-500">
                                                <a href="#" onClick={() => setCurrentPage(page)}
                                                   className={(page !== currentPage ? "flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                           : "flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90")
                                                       + ((page > currentPage + 2) && (page !== Math.ceil(totalUsers / itemsPerPage)) ? " hidden" : "")
                                                       + ((page < currentPage - 2) && (page !== 0) ? " hidden" : "")}>
                                                    {page}
                                                </a>
                                                <a href="#"
                                                   className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                       + (((page === currentPage - 3) && (page !== 0)) ? " " : " hidden")}>
                                                    {"..."}
                                                </a>
                                                <a href="#"
                                                   className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                       + (((page === currentPage + 3) && (page !== Math.ceil(totalUsers / itemsPerPage))) ? " " : " hidden")}>
                                                    {"..."}
                                                </a>
                                            </li>)
                                        )}
                                        <li className="rounded-r-lg bg-slate-150 dark:bg-navy-500">
                                            <a
                                                href="#"
                                                onClick={(event) => (currentPage < (Math.ceil(users.length / itemsPerPage)) ?
                                                    setCurrentPage((prev) => prev + 1) :
                                                    event.preventDefault())}
                                                className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M9 5l7 7-7 7"
                                                    />
                                                </svg>
                                            </a>
                                        </li>
                                    </ol>
                                    <div
                                        className="text-xs+">{(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalUsers)} of {totalUsers} entries
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={openEditUserModal}
                onClose={() => handleCloseEditUser()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Edit User</h1>
                    <button
                        onClick={() => handleCloseEditUser()}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 dark:text-white focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form className="scrollbar-sm dark:text-white overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Name</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="name"
                                    value={selectedUser.name}
                                    onChange={handleEditUserChange}
                                    type={"text"}
                                />
                            </label>
                            <label className="block">
                                <span>Email</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="email"
                                    value={selectedUser.email}
                                    onChange={handleEditUserChange}
                                    type={"text"}
                                />
                            </label>
                            <label className="block">
                                <span>User Role</span>
                                <div className="h-full  rounded-xl flex flex-col">
                                    <select name="user_role"
                                            onChange={handleEditUserChange}
                                            value={selectedUser.user_role}
                                            className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    >
                                        <option hidden disabled selected value=""> -- select an option --</option>
                                        <option value="student">student</option>
                                        <option value="teacher">teacher</option>
                                        <option value="admin">admin</option>
                                        <option value="teacher_assistant">teacher_assistant</option>
                                    </select>
                                </div>
                            </label>
                            <label className="block">
                                <span>Active</span>
                                <input
                                    className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                    name="is_active"
                                    checked={selectedUser.is_active}
                                    onChange={handleEditUserChange}
                                    type={"checkbox"}
                                />
                            </label>
                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => handleCloseEditUser()}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={editUser}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Edit
                    </button>
                </div>
            </Dialog>
        </main>
    );
}

export default AdminUserManagementPage;