
// let serverURL = "https://journicraft.com/api/";

// switch (process.env.NODE_ENV) {
//     case 'development':
//         serverURL = "https://journicraft.com/test/api/";
//         break;
//     case 'staging':
//         serverURL = "https://journicraft.com/staging/api/";
//         break;
//     default:
//         serverURL = "https://journicraft.com/api/";
//         break;
// } t

// console.log("****** serverURL:", serverURL, ", NODE_ENV:", process.env.NODE_ENV)
// server url list
const serverURL = "https://journicraft.com/api/";
// const serverURL = "https://journicraft.com/test/api/";
// const serverURL = "http://127.0.0.1:8080/api/";
// forget ?

const sidebarMenuColors  = {unselected:"#787878", selected:"#2196F3"};
const logoColors = {ligth: "#21cbaf", medium: "#20685C", dark: "#20403D"};

export {serverURL, sidebarMenuColors, logoColors}
