import {RouterProvider, createBrowserRouter, Navigate, useLocation} from "react-router-dom";

import {ProtectedRoute} from "./utils/ProtectedRoute";
import DashboardPage from "./pages/dashboard/dashboardPage";
import ChatPage from "./pages/chat/chatPage";
import LoginPage from "./pages/login/loginPage";
import RegisterPage from "./pages/register/registerPage";
import {useAuth} from "./utils/authProvider";
import Chat from "./pages/chat/components/chat";
import SiteLayout from "./layout/SiteLayout";
import AboutItem from "./layout/sidebarditems/aboutItem";
import AboutPage from "./pages/about/aboutPage";
import UserListItem from "./layout/sidebarditems/userListItem";
import UserListPage from "./pages/userlist/userListPage";
import UserPage from "./pages/userlist/components/userPage";
import UserChat from "./pages/userlist/components/userChat";
import ExerciseListPage from "./pages/exercise/exerciseListPage";
import ExercisePage from "./pages/exercise/components/exercisePage";
import ForgetPassPage from "./pages/login/forgetPassPage";
import ActivateAccountPage from "./pages/register/activateAccountPage";
import GoogleLoginCallback from "./pages/login/googleLoginCallback";
import Firstpage from "./pages/website/firstpage";
import CoursePage from "./pages/course/coursePage";
import PrivacyPage from "./pages/website/pages/privacy";
import MessageFolder from "./pages/message/components/messageFolder";
import {useEffect, useMemo, useState} from "react";
import {openCloseSidebarPanel} from "./utils/helpers";
import HistoryMessage from "./pages/message/components/historyMessage";
import ChatWizardType from "./pages/admin/chatWizard/chatWIzardType";
import AdminDashboard from "./pages/admin/adminDashboard/dashboard";
import WizardQuestions from "./pages/admin/chatWizardQuestions/questions";
import WizardEmbededModels from "./pages/admin/chatWizardEmbeddedModel/chatWizardEmbeddedModels";
import AddModel from "./pages/dashboard/addModel";
import {useCourseContext} from "./utils/courseContext";
import {useCookies} from "react-cookie";
import FetchClient from "./utils/fetchClient";
import MessagePage from "./pages/message/messagePage";
import AdminUserManagementPage from "./pages/admin/users/adminUserManagement";

const JRoutes = (props) => {
    const {stores} = useAuth();
    const [cookies] = useCookies();
    // const user_role = localStorage.getItem('user_role');
    // const user_role = stores?.user?.user_role;
    // Changed to handle cookiesss
    const user_role = cookies?.user?.user_role;
    const token = cookies?.token;

    const routesForPublic = [
        {
            path: "/service",
            element: <div>Service Page</div>,
        },
        {
            path: "/home",
            element: <Firstpage/>
        },
        {path: "*", element: <Firstpage/>},
    ];
    const routerForTeacherOnly = [{
        path: "/",
        element: <ProtectedRoute/>,
        children: [
            {
                path: "/",
                element: <Navigate to="/dashboard" replace={true}/>,
            },
            {
                path: "/login",
                element: <Navigate to="/dashboard" replace={true}/>,
            },
            {
                path: "/dashboard",
                element: <DashboardPage/>,
            },
            {
                path: "/about",
                element: <AboutPage/>
            },

            {
                path: "/course",
                element: <CoursePage/>,
            },
            {
                path: "/add-model/:id",
                element: <AddModel/>,
            },
            {
                path: "/chats",
                element: <ChatPage/>,
                children: [
                    {
                        path: "chat/:chat_id",
                        element: <Chat/>
                    }
                ]
            },
            {
                path: "/user_list",
                element: <UserListPage/>,
                children: [
                    {
                        path: "user/:user_id/course/:course_id",
                        element: <UserPage/>
                    },
                    {
                        path: "user/:user_id/chat/:chat_id",
                        element: <UserChat/>
                    }
                ]
            },
            {
                path: "/messages",
                element: <MessagePage/>,
                children: [
                    {
                        path: ":message_folder",
                        element: <MessageFolder/>
                    }
                ]
            },
            {
                path: "/messages/message/:id",
                element: <HistoryMessage/>,
            },
            {
                path: "/exercise_list",
                element: <ExerciseListPage/>,
                children: [
                    {
                        path: "/exercise_list/course/:course_id/exercise/:exercise_id",
                        element: <ExercisePage/>
                    }
                ]
            },
        ]
    }
    ]
    const routesForAdminOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>,
            children: [
                {
                    path: "/",
                    element: <Navigate to="/dashboard" replace={true}/>,
                },
                {
                    path: "/login",
                    element: <Navigate to="/dashboard" replace={true}/>,
                },
                {
                    path: "/dashboard",
                    element: <AdminDashboard/>,
                },
                {
                    path: "/assistants",
                    element: <AdminDashboard/>,
                },
                {
                    path: "/admin/users",
                    element: <AdminUserManagementPage/>,
                },
                {
                    path: "/about",
                    element: <AboutPage/>
                },
                {

                    path: "/home",
                    element: <Firstpage/>,
                },
                {
                    path: "/chat/wizard",
                    children: [
                        {
                            path: "/chat/wizard/type",
                            element: <ChatWizardType/>
                        },
                        {
                            path: "/chat/wizard/type/:id/questions",
                            element: <WizardQuestions/>
                        },
                        {
                            path: "/chat/wizard/type/:id/embedded-models",
                            element: <WizardEmbededModels/>
                        },
                    ]
                },
            ]
        }]

    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <SiteLayout/>,
            children: [
                {
                    path: "/",
                    element: <Firstpage/>,
                },
                {
                    path: "/home",
                    element: <Firstpage/>,
                },
                {
                    path: "/privacy",
                    element: <PrivacyPage/>,
                },
                {
                    path: "/login",
                    element: <LoginPage/>,
                },

                {
                    path: "/register",
                    element: <RegisterPage/>,
                },
                {
                    path: "/forget_password",
                    element: <ForgetPassPage/>,
                },
                {
                    path: "/activate_account",
                    element: <ActivateAccountPage/>,
                },
                {
                    path: "/oauth2/google/callback",
                    element: <GoogleLoginCallback/>,
                }
            ]
        }
    ];
    const router = useMemo(() => {

        return createBrowserRouter([
            ...routesForPublic,
            ...(!token ? routesForNotAuthenticatedOnly : []),
            ...(user_role !== "admin" ? routerForTeacherOnly : []),
            ...(user_role === "admin" ? routesForAdminOnly : [])
        ]);
    }, [user_role]);


    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>;
};

export default JRoutes;
