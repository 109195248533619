import {badgeWhistle} from "../utils/badgesIcons";

const startedBadge = {
    title: "Started",
    description: "You've started to Craft your Journi.",
    icon: "whistle",
    bgClass: "bg-secondary/10 dark:bg-secondary-light/40",
    order: 1
};

const firstChatBadge = {
    title: "First Chat",
    description: "You've created your first chat.",
    icon: "crayons",
    bgClass: "bg-info/10 dark:bg-info/40",
    order: 2
};

const fiveChatsBadge = {
    title: "5 Chats",
    description: "You've engaged in 5 conversations.",
    icon: "pencils",
    bgClass: "bg-info/10 dark:bg-info/40",
    order: 4
};

const tenChatsBadge = {
    title: "10 Chats",
    description: "You've participated in 10 conversations.",
    icon: "pens",
    bgClass: "bg-info/10 dark:bg-info/40",
    order: 6
};

const twentyChatsBadge = {
    title: "20 Chats",
    description: "You've participated in 20 conversations.",
    icon: "brushes",
    bgClass: "bg-info/10 dark:bg-info/40",
    order: 8
};

const messages20Badge = {
    title: "1000 Tokens",
    description: "Your chats have surpassed 1,000 tokens!",
    icon: "eraser",
    bgClass: "bg-success/10 dark:bg-success/40",
    order: 3
};

const messages100Badge = {
    title: "10,000 Tokens",
    description: "Your chats have surpassed 10,000 tokens!",
    icon: "post_it",
    bgClass: "bg-success/10 dark:bg-success/40",
    order: 5
};

const messages200Badge = {
    title: "20,000 Tokens",
    description: "Your chats have surpassed 20,000 tokens!",
    icon: "eraser",
    bgClass: "bg-success/10 dark:bg-success/40",
    order: 7
};

const messages500Badge = {
    title: "50,000 Tokens",
    description: "Your chats have surpassed 50,000 tokens!",
    icon: "sharpener",
    bgClass: "bg-success/10 dark:bg-success/40",
    order: 9
};

const messages1000Badge = {
    title: "100,000 Tokens",
    description: "Your chats have surpassed 100,000 tokens!",
    icon: "compass_draw",
    bgClass: "bg-success/10 dark:bg-success/40",
    order: 11
};

const bestOfThis = {
    title: "Best of This",
    description: "Your are the best of this!",
    icon: "flask_chemistry",
    bgClass: "bg-warning/10 dark:bg-warning/40",
    order: 10
};

const bestOfThat = {
    title: "Best of that",
    description: "Your are the best of that!",
    icon: "microscope",
    bgClass: "bg-warning/10 dark:bg-warning/40",
    order: 12
};

const badgeList = [
    startedBadge,
    firstChatBadge,
    fiveChatsBadge,
    tenChatsBadge,
    twentyChatsBadge,
    messages20Badge,
    messages100Badge,
    messages200Badge,
    messages500Badge,
    messages1000Badge,
    bestOfThis,
    bestOfThat
];

const getBadgeList = (is_active, number_of_chats, number_of_messages) => {
    const badge_list = [];
    if (is_active) {
        badge_list.push(startedBadge);
    }
    if (number_of_chats >= 1) {
        badge_list.push(firstChatBadge);
    }
    if (number_of_chats >= 5) {
        badge_list.push(fiveChatsBadge);
    }
    if (number_of_chats >= 10) {
        badge_list.push(tenChatsBadge);
    }
    if (number_of_chats >= 20) {
        badge_list.push(twentyChatsBadge);
    }
    if (number_of_messages >= 20) {
        badge_list.push(messages20Badge);
    }
    if (number_of_messages >= 100) {
        badge_list.push(messages100Badge);
    }
    if (number_of_messages >= 200) {
        badge_list.push(messages200Badge);
    }
    if (number_of_messages >= 500) {
        badge_list.push(messages500Badge);
    }
    if (number_of_messages >= 1000) {
        badge_list.push(messages1000Badge);
    }
    return badge_list;
}

export {
    badgeList, getBadgeList
};