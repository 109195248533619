import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../../components/tab_pannel';
import userIcon from '../../../images/user-black.png';
import ChatMessage from "../chat/components/chatMessage";
import grey from '@mui/material/colors/grey';
import {FormControl, FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material';
import SimpleChat from './simpleChat';
import {useState} from 'react';
import FetchClient from "../../utils/fetchClient";
import * as yup from 'yup';
import {useLayoutContext} from '../../layout/layoutProvider';
import Chat from '../chat/components/chat';
import {useNavigate} from 'react-router-dom';
import AddModel from './addModel';
import {useRef} from 'react';
import {useEffect} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const ModelChat = (props) => {
    const [modelLoading, setModelLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [newSentMessge, setNewSentMessge] = useState();
    const handleChange = (event, newValue) => {
        if (newValue === 1 && selectedModel === "") {
            window.$notification({
                text: 'Please select a model',
                variant: 'error',
                position: 'center-top'
            });
        } else {
            setValue(newValue);
        }
    };
    const {setLoader} = useLayoutContext();

    const questions = props?.questions;
    const assistant = props?.assistant;
    const wizard = props?.wizard;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [currentAnswer, setCurrentAnswer] = useState('');
    const [showActionItems, setShowActionItems] = useState(true);
    const [chatName, setChatName] = useState("");
    const [errorsList, setErrorsList] = useState([]);
    const [addModel, setAddModel] = useState(false);
    const [modelsList, setModelsList] = useState([]);
    const [selectedModel, setSelectedModel] = useState("");
    const [newAnswerArray, setNewAnswerArray] = useState([]);
    const [newChat, setNewChat] = useState("");
    const [embedContent, setEmbedContent] = useState(true);
    const handleNext = () => {
        if (currentAnswer === "") {
            window.$notification({
                text: 'Please fill the answer',
                variant: 'error',
                position: 'center-top'
            });
        } else {
            setAnswers(prevAnswers => {
                const updatedAnswers = {
                    ...prevAnswers,
                    [questions[currentQuestionIndex]?.id]: currentAnswer
                };
                if (currentQuestionIndex === questions.length - 1) {
                    changeAnswerFormat(updatedAnswers);
                    setValue(2);
                } else {
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                }
                setCurrentAnswer('');
                return updatedAnswers;
            });
        }
    };
    useEffect(() => {
        srollToEndOfMessages();
    }, [answers]);

    function srollToEndOfMessages() {
        messagesEndRef?.current?.scrollIntoView({behavior: "smooth"});
    }

    const messagesEndRef = useRef(null)
    const changeAnswerFormat = (updatedAnswers) => {
        setShowActionItems(false);
        const keys = Object.keys(updatedAnswers);
        setNewAnswerArray(keys.map(key => ({
            question_id: parseInt(key),
            answer: updatedAnswers[key]
        })));
    };


    const handleChangeAnswers = (e) => {
        e.preventDefault()
        setCurrentAnswer(e.target.value);
    };

    const sendCommand = (chat) => {
        if (chat?.id) {
            FetchClient({
                method: 'post',
                url: "chat/message",
                data: {
                    content: wizard?.command,
                    role: 'user',
                    chat_id: chat?.id,
                    type: 'message'
                }
            }).then(response => {
                setNewChat(chat);
                setNewSentMessge(response?.data)
            });
        } else {
        }
    }
    const handleCreateChat = async () => {
        const schema = yup.object().shape({
            chatName: yup.string().required("Name is required !"),
        });
        try {
            await schema.validate({chatName}, {abortEarly: false});
            setLoader(true);
            FetchClient({
                method: 'post',
                url: 'chat/create/by/wizard',
                data: {
                    answers: newAnswerArray,
                    course_id: props.selectedCourse?.id,
                    user_id: props?.userProfile?.id,
                    chat_type_id: props?.wizard?.id,
                    embedd_course_contents: embedContent,
                    title: chatName,
                    chat_wizard_embedding_model_id: parseInt(selectedModel)===-1?null:parseInt(selectedModel)
                }
            }).then(response => {
                setChatName("");
                setLoader(false);
                setValue(3);

                sendCommand(response?.data);
                setErrorsList([]);
                window.$notification({
                    text: 'Your chat is created !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }

    };


    const getModelsList = () => {
        FetchClient({
            method: 'get',
            params: {
                chat_type_id: wizard?.id,
                course_id: props.selectedCourse?.id
            },
            url: `chat/wizard/embedding/model`,

        }).then(response => {
            if (response?.data?.total === 0) {
                setModelsList([{name: "Course Content", id: -1}]);
            } else {
                setModelsList(response?.data?.items);
            }
            setModelLoading(false);
        })
    }

    React.useEffect(() => {
        if (!addModel) {
            setModelLoading(true);
            getModelsList();
        }
    }, [addModel])
    return (
        <Box className="card w-full justify-start dark:bg-navy-800 mt-0">
            {addModel ?
                <AddModel back={(state) => setAddModel(state)} wizard={props.wizard}/>
                :
                <>
                    <div class="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                        <div class="flex items-center space-x-2">
                            <div
                                class="flex h-7 w-7 items-center justify-center rounded-full bg-primary/10 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                                <span>{value + 1}</span>
                            </div>
                            <h4 class="text-lg font-medium text-slate-700 dark:text-navy-100">
                                {wizard.name}
                            </h4>
                        </div>
                    </div>
                    <div className="flex flex-col divide-y divide-slate-150 px-4 dark:divide-navy-500 sm:px-5">
                        <div className="mt-8">
                            <ol className="steps line-space [--size:.75rem] [--line:1px]">
                                <li className="step before:bg-slate-200 dark:before:bg-navy-500">
                                    <div className="step-header rounded-full bg-primary dark:bg-accent">
                                        <span
                                            className={`${value === 0 ? "" : "hidden "} inline-flex h-full w-full animate-ping rounded-full bg-primary opacity-80 dark:bg-primary-light`}></span>
                                    </div>
                                    <h3
                                        className={`${value === 0 ? "font-bold " : "cursor-pointer "} text-xs+ text-slate-600 dark:text-navy-100`}
                                        // onClick={() => value === 0 && setValue(0)}
                                    >
                                        Step 1: Select a Model
                                    </h3>
                                </li>
                                <li className="step before:bg-slate-200 dark:before:bg-navy-500">
                                    <div className="step-header rounded-full bg-secondary dark:bg-secondary-light">
                                        <span
                                            className={`${value === 1 ? "" : "hidden "} inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80 dark:bg-secondary-light`}></span>
                                    </div>
                                    <h3
                                        className={`${value === 1 ? "font-bold " : "cursor-pointer "} text-xs+ text-slate-600 dark:text-navy-100`}
                                        // onClick={() => (value === 0 || value === 1) && setValue(1)}
                                    >
                                        Step 2: Specify your objectives
                                    </h3>
                                </li>
                                <li className="step before:bg-slate-200 dark:before:bg-navy-500">
                                    <div className="step-header rounded-full bg-error">
                                        <span
                                            className={`${value === 2 ? "" : "hidden "} inline-flex h-full w-full animate-ping rounded-full bg-error opacity-80 dark:bg-error-light`}></span>
                                    </div>
                                    <h3
                                        className={`${value === 2 ? "font-bold " : "cursor-pointer "} text-xs+ text-slate-600 dark:text-navy-100`}
                                        // onClick={() => (value === 1 || value === 2) && setValue(2)}
                                    >
                                        Step 3: Write chat title
                                    </h3>
                                </li>
                                <li className="step before:bg-slate-200 dark:before:bg-navy-500">
                                    <div className="step-header rounded-full bg-lime-500 firefox:!-mt-1.5">
                                        <span
                                            className={`${value === 3 ? "" : "hidden "} inline-flex h-full w-full animate-ping rounded-full bg-lime-500 opacity-80 dark:bg-lime-100`}></span>
                                    </div>
                                    <h3
                                        className={`${value === 3 ? "font-bold " : "cursor-pointer "} text-xs+ text-slate-600 dark:text-navy-100`}
                                        // onClick={() => (value === 2 || value === 3) && setValue(3)}
                                    >
                                        Step 4: Chat
                                    </h3>
                                </li>
                            </ol>

                        </div>
                        <div class="my-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                        <TabPanel value={value} index={0} className="border-none pb-5">
                            <ChatMessage asssitant_image_url={assistant?.image_file_url}
                                         lastItem={false} role="assistant"
                                         loading={false}
                                         key={2}
                                         datetime={"2/20/2024"}
                                         content={"First you can specifiy your base model for the generation of the questions."}
                                         id={2}
                            />
                            <div
                                className={"flex mt-8 text-center items-center text-primary justify-center" + (modelLoading ? "" : " hidden")}>
                                <span className="px-2">Please wait ... </span>
                                <ClipLoader
                                    color={"blue"}
                                    loading={modelLoading}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                            {modelsList.length > 0 &&
                                <div className="flex items-start space-x-2.5 sm:space-x-5 mt-4">
                                    <div className="popper-ref avatar">
                                        <img
                                            className="rounded-full"
                                            src={userIcon}
                                            alt="she"
                                        />
                                    </div>
                                    <div className="flex flex-col items-start space-y-3.5">
                                        <div className="mr-4 sm:mr-10">
                                            <div
                                                className="text-base rounded-2xl rounded-tl-none bg-teal-100 p-3 text-slate-700 shadow-sm dark:bg-blue-800 dark:text-slate-300"
                                            >
                                                <FormControl sx={{
                                                    // border: `1px solid ${grey[400]}`,
                                                    padding: "10px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    borderRadius: "4px"
                                                }} className='w-full'>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        onChange={(e) => setSelectedModel(e.target.value)}
                                                    >
                                                        {modelsList.length > 0 && modelsList.map((item, index) => (
                                                            <FormControlLabel value={item?.id} control={<Radio/>}
                                                                              label={item?.name}/>
                                                        ))}
                                                    </RadioGroup>

                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Grid className='flex justify-between mt-5'>
                                <button onClick={() => setAddModel(true)}
                                        className="btn w-30 border font-medium text-white bg-blue-600 hover:bg-blue-700 focus:bg-blue-800 active:bg-blue-800/80 dark:bg-navy-700 dark:text-slate-300 dark:border-navy-500 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                                    <span>Create a new model</span>
                                </button>
                                <button
                                    className="btn w-24 border font-medium text-white dark:text-slate-100 dark:border-navy-500 bg-jclogo-300 dark:bg-jclogo-200 hover:bg-jclogo-200 dark:hover:bg-jclogo-100"
                                    onClick={(event) => handleChange(event, 1)}>
                                    <span className="mr-2">Next</span>
                                    <svg width="15px" height="15px" viewBox="0 0 1024 1024" class="icon"
                                         xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                                                fill="#ffffff"></path>
                                        </g>
                                    </svg>
                                </button>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1} className="border-none pb-5">
                            <div className="flex flex-col justify-center !-mx-8 h-[calc(100vh-23rem)]">
                                {questions.length > 0 ?
                                    <div className="w-full scrollbar-sm grow overflow-y-auto">
                                        <div className="mr-4">
                                            {Object.keys(answers).map((questionId, index) => (
                                                <div key={questionId} className="mb-4">
                                                    <ChatMessage asssitant_image_url={assistant?.image_file_url}
                                                                 lastItem={false}
                                                                 role="assistant" loading={false} key={index}
                                                                 content={questions?.find(q => q?.id === parseInt(questionId))?.question}
                                                                 id={questions?.find(q => q?.id === parseInt(questionId)).question?.id}/>
                                                    <ChatMessage asssitant_image_url={assistant?.image_file_url}
                                                                 lastItem={false}
                                                                 role="user" loading={false} key={index + "answer"}
                                                                 content={answers[questionId]}
                                                                 id={index + "answer"}/>
                                                </div>
                                            ))}
                                        </div>
                                        <div ref={messagesEndRef}/>
                                        {showActionItems ?
                                            <div className="w-full ">
                                                <ChatMessage asssitant_image_url={assistant?.image_file_url}
                                                             lastItem={false}
                                                             role="assistant" loading={false}
                                                             key={questions[currentQuestionIndex]?.id}
                                                             datetime={"2/20/2024"}
                                                             content={`${questions[currentQuestionIndex]?.question}`}
                                                             id={questions[currentQuestionIndex]?.id}/>
                                            </div>
                                            : ""
                                        }

                                    </div> :
                                    <span className='w-full flex justify-center text-error-focus font-bold"'>There are no questions !</span>
                                }
                            </div>
                            <div className='flex flex-col w-full justify-end'>
                                <div
                                    className={"flex h-12 !-mx-8 shrink-0 items-center justify-between border-t border-slate-200 bg-white px-[calc(var(--margin-x)-.25rem)] transition-[padding,width] duration-[.25s] dark:border-navy-600 dark:bg-navy-800"}>
                                    <div className="flex flex-1 space-x-2">
                                        <input
                                            className="form-input h-9 w-full bg-transparent placeholder:text-slate-500 "
                                            placeholder="Write your answer"
                                            name="content"
                                            value={currentAnswer}
                                            onChange={handleChangeAnswers}
                                            type={"text"}/>
                                    </div>
                                    {showActionItems ?
                                        <button
                                            className={`${currentAnswer !== "" && currentQuestionIndex <= questions.length ? " bg-jclogo-300 dark:bg-jclogo-200 hover:bg-jclogo-200 dark:hover:bg-jclogo-100" : "bg-slate-500 dark:bg-slate-700"} btn w-24 border font-medium text-white dark:text-slate-100 dark:border-navy-500`}
                                            onClick={(event) => {
                                                handleNext()
                                            }}>
                                            <span>{currentAnswer !== "" && currentQuestionIndex === questions.length ? "Done" : "Next"}</span>
                                            <svg width="15px" height="15px" viewBox="0 0 1024 1024" class="icon"
                                                 version="1.1"
                                                 xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                   strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path
                                                        d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                                                        fill="#ffffff"></path>
                                                </g>
                                            </svg>
                                        </button>
                                        : ""}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2} className="border-none">
                            <h1>Please write a name for your chat</h1>
                            <label className="block">
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="name"
                                    value={chatName}
                                    onChange={(e) => setChatName(e.target.value)}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "chatName")?.[0]?.errors?.[0]}</span>

                            </label>
                            <label className="block">
                                <span>Do you want to embed course content?</span>
                                <input
                                    className="form-checkboxis-outline mt-2 ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                    type="checkbox"
                                    checked={embedContent}
                                    onChange={(e) => setEmbedContent(e.target.checked)}
                                />
                            </label>
                            <div className='w-full flex justify-end mt-4 mb-4'>
                                <button
                                    className="btn w-24 border font-medium text-white dark:text-slate-100 dark:border-navy-500 bg-jclogo-300 dark:bg-jclogo-200 hover:bg-jclogo-200 dark:hover:bg-jclogo-100"
                                    onClick={() => handleCreateChat()}>
                                    <span>Done</span>
                                    <svg width="15px" height="15px" viewBox="0 0 1024 1024" class="icon" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                                                fill="#ffffff"></path>
                                        </g>
                                    </svg>
                                </button>
                            </div>

                        </TabPanel>
                        <TabPanel value={value} index={3} className="border-none">
                            {newChat?.id &&
                                <Chat message={newSentMessge} wizard={newChat} isWizard={true}
                                      screenHeight={"h-[calc(100vh-18rem)]"} topMargin={"mt-0"} sidePadding={"!-mx-8"}/>
                            }

                        </TabPanel>
                    </div>
                </>}
        </Box>
    );
}
export default ModelChat;