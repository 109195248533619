import React, {useEffect, useState} from "react";
import * as yup from 'yup';
import {useLayoutContext} from "../../../layout/layoutProvider";
import {useLocation, useOutletContext} from "react-router-dom";
import Main from "../../../main";
import {openCloseSidebarPanel} from "../../../utils/helpers";
import FetchClient from "../../../utils/fetchClient";
import {useAuth} from "../../../utils/authProvider";
import Dialog from '@mui/material/Dialog';
import Select from 'react-select';
import Popper from '../../../components/popper'

const WizardQuestions = () => {
    const location = useLocation()
    const [questionsList, setQuestionsList] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [ selectedQuestion, setSelectedQuestion ] = useState(null);
    const course = useOutletContext().course;
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();

     const wizardTypeTable = questionsList?.length > 0 && questionsList.map((question, index) => (
        <tr key={"question_"+question?.id} className="border-y border-transparent cursor-pointer border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{question?.question}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 flex">
                <button
                    onClick={() => handleDeleteQuestionModal(question)}
                    className="flex darkmode-toggle btn h-8 w-8 rounded-full p-1 m-1 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    id={"delete" + question?.id}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" baseProfile="basic">
                        <g id="Icon-Set-Filled" transform="translate(-469.000000, -1041.000000)" fill="#c74343">
                            <path
                                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48">
                            </path>
                        </g>
                    </svg>
                </button>
            </td>
        </tr>)
    );
    const handleDeleteQuestionModal = (item) =>{
        setConfirmDeleteModal(true);
        setSelectedQuestion(item);
      }
    const getQuestionsList = () =>{
        FetchClient({
            method: 'get',
            params:{
                chat_wizard_id:window.location.pathname.split("/")[4]
            },
            url: `chat/wizard/questions`,

        }).then(response => {
            setQuestionsList(response?.data)
        })
    };
    const handleDeleteQuestion = () =>{
        FetchClient({
            method: 'delete',
            url: 'admin/chat/wizard/question/delete',
            params: {
                id  : selectedQuestion?.id
            },
        }).then(response => {
            window.$notification({
                text: 'Question is deleted successfully!',
                variant: 'primary',
                position: 'center-top'
            })
            setLoader(false);
            setConfirmDeleteModal(false);
            getQuestionsList();
        }).catch((error) => {
            if (error.response) {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                })
            }
        });
      }

    useEffect(() => {
        getQuestionsList();
    }, [location]);
    return (
        <main className="main-content h-100vh mt-0">
            <div
                className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-2 mt-2 transition-all duration-[.25s]">
                <div>
                    <div className="card mt-18">
                    <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                        <table className="is-hoverable w-full text-left">
                            <thead>
                            <tr>
                                <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    #
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Question
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {questionsList.length > 0 && wizardTypeTable}
                            </tbody>
                        </table>
                    </div>
                </div>

                </div>
            </div>
            <Dialog
                open={confirmDeleteModal}
                onClose={()=> setConfirmDeleteModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                    alt="logo"
                />
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Delete
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to delete this queston ?
                    </p>
                    <div className="space-x-2 text-center mt-4">
                        <button
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setConfirmDeleteModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleDeleteQuestion}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>

        </main>
    );
}

export default WizardQuestions;