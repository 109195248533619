import JoinNowImage from "../../../../../images/join_now.webp";
import {useTranslation} from "react-i18next";

const JoinMessageContainer = () => {
    const {t} = useTranslation();

    return (
        <>
            <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid md:grid-cols-7 gap-4 md:gap-8 xl:gap-20 md:items-center">
            <div class="md:col-span-4">
                        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-slate-300 text-gray-800 mb-8">
                            {t("website_join_message.title")}
                        </h2>
                        <p className="text-xl dark:text-gray-500 text-slate-800">
                            {t("website_join_message.description")}
                        </p>
                        <a className="py-3 px-4 mt-8 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-jclogo-400 text-white dark:text-slate-300 hover:bg-jclogo-600 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                           href="/login">
                            {t("website_join_message.join")}
                            <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path d="m9 18 6-6-6-6"/>
                            </svg>
                        </a>
                    </div>
                    <div class="md:col-span-3 relative ms-4">
                        <img
                            src={JoinNowImage}
                            alt="JOURNiCRAFT"
                            className="w-full rounded-xl"                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default JoinMessageContainer;