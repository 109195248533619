import React, {useEffect, useState} from "react";

import {useLayoutContext} from "../../layout/layoutProvider";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {openCloseSidebarPanel} from "../../utils/helpers";
import FetchClient from "../../utils/fetchClient";
import ButtonCard from "../../components/button_card";
import ChatButtonCard from "../../components/chat_button_card";
import SimpleChat from "./simpleChat";
import ModelChat from "./modelChat";
import {useAuth} from "../../utils/authProvider";
import DashboardFaqContainer from "./items/dashboardFaqContainer";
import ClipLoader from "react-spinners/ClipLoader";

const DashboardPage = (props) => {
    const navigate = useNavigate();
    const {store, setStore} = useAuth()
    const location = useLocation()
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [loading, setLoading] = useState(true);
    const [waitForAcceptance, setWaitForAcceptance] = useState(true);
    const [profile, setProfile] = useState({
        general_chat_number: 0, exercise_chat_number: 0, usage_prompt_tokens: 0, usage_completion_tokens: 0
    });
    const [badge, setBadge] = useState();
    const [wizardTypesList, setWizardTypesList] = useState([]);
    const [selectedWizard, setSelectedWizard] = useState({});
    const [questions, setQuestions] = useState([]);
    const [assistant, setAssistant] = useState({});
    const [course, setCourse] = useState({});
    const [generalAssistants, setGeneralAssistants] = useState([]);

    const user = useOutletContext().user;
    const thisCourse = useOutletContext().course;

    function updateMenu() {
        if (location.pathname.includes('dashboard')) {
            setSidebarSelectedMenuItem('dashboard');
        }
    }

    const getWizardTypesList = () => {
        FetchClient({
            method: 'get',
            url: `chat/wizard/types`,
            params: {
                enabled_for_student: thisCourse?.role === 'student' ? true : false
            }
        }).then(response => {
            setWizardTypesList(response?.data?.items);
            setLoading(false);
        })
    }
    const getUserProfile = () => {
        FetchClient({
            method: 'get',
            url: `profile`
        }).then(response => {
            setProfile(response.data);
            localStorage.setItem("user", response.data)
        })
    }

    useEffect(() => {
        setLoading(true);
        setCourse(thisCourse);

        getUserProfile();
        updateMenu();
        openCloseSidebarPanel(false);
        getWizardTypesList();
        getGeneralAssistants();
    }, [location]);

    const getWizardQuestions = (context) => {
        FetchClient({
            method: 'get',
            url: `chat/wizard/questions`,
            params: {
                chat_wizard_id: context?.id,
            }
        }).then(response => {
            setQuestions(response.data)
        })
    }
    const handleSelectWizard = (context) => {
        if (context === "general") {
            setSelectedWizard({is_wizard: false});
            getAssistant(context);
        } else {
            setSelectedWizard(context);
            getWizardQuestions(context);
            getAssistant(context);
        }
    }
    const getGeneralAssistants = () => (
        FetchClient({
            method: 'get',
            url: 'assistants',
            params: {}
        }).then(response => {
            setGeneralAssistants(response.data);
        })
    );
    const getAssistant = (context) => (
        FetchClient({
            method: 'get',
            url: '/assistants'
        }).then(response => {
            setAssistant(response?.data.filter((item) => item.id === context?.assistant_id)[0])
        })
    );
    const getAssistantInfo = (this_assistant_id) => {
        return generalAssistants?.filter((item) => item.id === this_assistant_id)[0];
    }

    let requestForAcceptanceInterval = null;
    function getUserCourses() {
        FetchClient({
            method: 'get',
            url: 'user/' + user?.id + '/courses',
            params: {
                user_id: user?.id
            }
        }).then(response => {
            if (response?.data !== undefined && response?.data?.length > 0) {
                let courses = response?.data;
                let index = courses.findIndex((obj => obj.id === course.id));
                if (courses[index]?.status === "subscribed") {
                    clearInterval(requestForAcceptanceInterval);
                    setStore("course", courses[index]);
                    setCourse(courses[index]);
                    if (course?.status !== "subscribed")
                        window.location.reload();
                }
            }
        }).catch((error) => {
        });
    }

    useEffect(() => {
        if (course?.status === "subscribed") setWaitForAcceptance(false);
        if (course?.status !== "subscribed" && waitForAcceptance) {
            requestForAcceptanceInterval =
                setInterval(() => getUserCourses(), 2e3);
            return () => {clearInterval(requestForAcceptanceInterval)};
        }
    }, [waitForAcceptance]);

    return (
        <main className="main-content h-100vh mt-0">
            <div
                className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-14 transition-all duration-[.25s]">
                {course && course?.status !== "subscribed" ?
                    <div>
                        {course?.status !== 'requested' ? "" :
                            <div
                                className="mx-auto my-12 p-2 max-w-[95%] h-auto rounded-md text-left bg-red-800 text-slate-300 dark:text-slate-300">
                                You are not accepted into a course. You will have access the JOURNiCRAFT tools when your
                                teacher accepts your request. After that, you can see chat menu in the left sidebar.
                            </div>}
                        <DashboardFaqContainer/>
                    </div>
                    : <></>
                }
                {((course?.role === "student" || course?.role === "teacher" || course?.role === "human_teacher_assistant") && course?.status === "subscribed") ?
                    <div className="grid grid-cols-12 gap-1 mt-4">
                        <div className="flex flex-col h-[calc(100vh-6rem)] col-span-12 md:col-span-5">
                            <div className="scrollbar-sm grow overflow-y-auto pr-2">
                                <div className="grid grid-cols-2 gap-1">
                                    <div className={"col-span-2"} onClick={() => {
                                        // handleSelectWizard("general")
                                        navigate("/chats");
                                    }}>
                                        <ButtonCard color={"bg-gray-300 dark:bg-gray-700 dark:text-navy-100 text-navy-800"} title={"Your Chat List"}/>
                                    </div>
                                    <div
                                        className={"flex col-span-2 mt-24 text-center items-center text-primary justify-center" + (loading ? "" : " hidden")}>
                                        <span className="px-2">Please wait ... </span>
                                        <ClipLoader
                                            color={"blue"}
                                            loading={loading}
                                            size={20}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                    {wizardTypesList.map((context, index) => {
                                        return (
                                            <div
                                                className={`${context?.is_wizard ? "" : ""} col-span-1 h-full` + (loading ? " hidden" : "")}
                                                onClick={() => {
                                                    handleSelectWizard(context)
                                                }}>
                                                <ChatButtonCard key={index}
                                                                selected={selectedWizard?.id === context.id}
                                                                bg_color={(index % 7 === 1 ? "bg-colorVariants-lime_200 dark:bg-colorVariantsDark-lime_800" :
                                                                    index % 7 === 2 ? "bg-colorVariants-fuchsia_200 dark:bg-colorVariantsDark-fuchsia_800" :
                                                                        index % 7 === 3 ? "bg-colorVariants-red_200 dark:bg-colorVariantsDark-red_800" :
                                                                            index % 7 === 4 ? "bg-colorVariants-amber_200 dark:bg-colorVariantsDark-amber_800" :
                                                                                index % 7 === 5 ? "bg-colorVariants-emerald_200 dark:bg-colorVariantsDark-emerald_800" :
                                                                                    index % 7 === 6 ? "bg-colorVariants-teal_200 dark:bg-colorVariantsDark-teal_800" :
                                                                                        "bg-colorVariants-blue_200 dark:bg-colorVariantsDark-blue_800")}
                                                                title={context.name}
                                                                description={context.description}
                                                                image={getAssistantInfo(context.assistant_id)?.image_file_url}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {selectedWizard.is_wizard === true ?
                            <div key={selectedWizard.id}
                                 className="col-span-12 md:col-span-7">
                                <ModelChat userProfile={profile} selectedCourse={course}
                                           assistant={assistant}
                                           wizard={selectedWizard} questions={questions}/>
                            </div>
                            :
                            selectedWizard.is_wizard === false ?
                                <div key={selectedWizard.id}
                                     className="col-span-12 md:col-span-7">
                                    <SimpleChat userProfile={profile} course={course}
                                                wizard={selectedWizard} assistants={generalAssistants}/>

                                </div>
                                :
                                <div
                                    class="col-span-12 justify-center md:col-span-7 h-fit m-12 alert flex rounded-full bg-primary/10 py-4 px-4 text-primary dark:bg-accent-light/15 dark:text-accent-light sm:px-5">
                                    Select one of the chat topics to continue.
                                </div>
                        }

                    </div>
                    : ""
                }
            </div>
        </main>
    );
}

export default DashboardPage;