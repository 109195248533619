import OneImage from "../../../../../images/one.svg";
import TwoImage from "../../../../../images/two.svg";
import ThreeImage from "../../../../../images/three.svg";
export const getStartedList = [
    {
        title:"website_get_started.item_1.title",
        image:OneImage,
        description:"website_get_started.item_1.description",},
    {
        title:"website_get_started.item_2.title" ,
        image: TwoImage,
        description:"website_get_started.item_2.description"
    },
    {
        title:"website_get_started.item_3.title",
        description:"website_get_started.item_3.description",
        image:ThreeImage
     }

  ]