import TimeSavingImage from "../../../../../images/time.svg";
import CreativityImage from "../../../../../images/creativity.svg";
import AccessibilityImage from "../../../../../images/accessible.svg";
import PersonalizedImage from "../../../../../images/personalized.svg";
import EngagementImage from "../../../../../images/engagement.svg";
import UnderstandingImage from "../../../../../images/understanding.svg";

export const benefitList = [
    {
      title: "website_benefits.subtitle_1",
      items: [
        {
          text: 'website_benefits.item_1.title',
          description: 'website_benefits.item_1.description',
          paperColor: '#FCF2FE', 
          image: TimeSavingImage,
          insideCardColors:[
            "#F6B2FF","#DF9AF8"
          ]
        },
        {
          text: 'website_benefits.item_2.title',
          description: 'website_benefits.item_2.description',
          paperColor: '#F0F0FF',  
          image: CreativityImage,
          insideCardColors:[
            "#B0B0FE","#B0A0F9"
          ]
        },
        {
          text: 'website_benefits.item_3.title',
          description: 'website_benefits.item_3.description',
          paperColor: '#FFFAE6', 
          image : AccessibilityImage,
          insideCardColors:[
            "#FFE489","#FEDDA9"
          ]
        },
      ],
    },
    {
      title: "website_benefits.subtitle_2",
      items: [
        {
          text: 'website_benefits.item_4.title',
          description: 'website_benefits.item_4.description',
          paperColor: '#F8FBE7', 
          image: PersonalizedImage,
          insideCardColors:[
            "#D3E776","#B4DA6D"
          ]
        },
        {
          text: 'website_benefits.item_5.title',
          description: 'website_benefits.item_5.description',
          paperColor: '#E4F7F9',  
          image: EngagementImage,
          insideCardColors:[
            "#5CDCE8","#41C7D8"
          ]
        },
        {
          text: 'website_benefits.item_6.title',
          description: 'website_benefits.item_6.description',
          paperColor: '#BCF5D8',  
          image: UnderstandingImage,
          insideCardColors:[
            "#86EDB8","#6ED9AA"
          ]
        },
      ],
    },
  ];