import React, {useEffect, useState} from "react";
import * as yup from 'yup';
import {useLayoutContext} from "../../../layout/layoutProvider";
import {useLocation, useOutletContext} from "react-router-dom";
import FetchClient from "../../../utils/fetchClient";
import Dialog from '@mui/material/Dialog';

const WizardEmbededModels = () => {
    const location = useLocation()
    const [modelsList, setModelsList] = useState([]);
    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [modelFile, setModelFile] = useState("");
    const [ selectedModel, setSelectedModel ] = useState(null);
    const {setLoader} = useLayoutContext();
    const [errorsList, setErrorsList] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

     const modelsTable = modelsList?.length > 0 && modelsList.map((model, index) => (
        <tr key={"question_"+model?.id} className="border-y border-transparent cursor-pointer border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{model?.name}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 flex">
                <button
                    onClick={() => handleAddFileModal(model)}
                    className="flex darkmode-toggle btn h-8 w-8 rounded-full p-1 m-1 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    id={"delete" + model?.id}
                >
                 <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#087d2d" strokeWidth="1.5"></circle> <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#087d2d" strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>
                </button>
            </td>
            {model?.file_name && <td className="whitespace-nowrap px-4 py-3 sm:px-5 flex">
                <button
                    onClick={() => handleDeletFileModal(model)}
                    className="flex darkmode-toggle btn h-8 w-8 rounded-full p-1 m-1 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    id={"delete" + model?.id}
                >
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" baseProfile="basic">
                        <g id="Icon-Set-Filled" transform="translate(-469.000000, -1041.000000)" fill="#c74343">
                            <path
                                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48">
                            </path>
                        </g>
                    </svg>                </button>
            </td>}
        </tr>)
    );
    const handleDeletFileModal = (item) =>{
        setConfirmDeleteModal(true);
        setSelectedModel(item);
      }
    const handleAddFileModal = (item) =>{
        setUploadFileModal(true);
        setSelectedModel(item);
      }
    const getEmbedingModels = () =>{
        FetchClient({
            method: 'get',
            params:{
                chat_type_id:window.location.pathname.split("/")[4],
                course_id: -1
            },
            url: `chat/wizard/embedding/model`,

        }).then(response => {
            setModelsList(response?.data?.items)
        })
    };
    const handleCloseAddFileModal = () => {
        setUploadFileModal(false);
        setModelFile("");
        setErrorsList([]);
     }
    
    const handleUploadFile =  async () =>{
            const schema = yup.object().shape({
                modelFile: yup.string().required("File is required !"), 
            });
            try {
                await schema.validate( {modelFile} , { abortEarly: false });  
                setLoader(true);
                setUploadFileModal(false);
                const formData = new FormData();
                formData.append('file', modelFile);
                FetchClient({
                  method: 'post',
                  url: 'admin/chat/wizard/embedding/model/file/add',
                  params:{
                    chat_wizard_embedding_model_id: selectedModel?.id,
                    course_id: null
                  },
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  data: formData          
              }).then(response => {
                setModelFile("");
                setUploadFileModal(false);
                setLoader(false);
                getEmbedingModels();
                setErrorsList([]);
                window.$notification({
                  text: 'Your File is added !',
                  variant: 'success',
                  position: 'center-top'
              })
              }).catch((error)=>{
                  window.$notification({
                  text: 'Oops! There was an error',
                  variant: 'error',
                  position: 'center-top'
              });
              })
            } catch (err) {
                setErrorsList(err.inner);
            }
    
        };

    useEffect(() => {
        getEmbedingModels();
    }, [location]);

    const handleDeleteFile = () =>{
        FetchClient({
            method: 'post',
            url: 'admin/chat/wizard/embedding/model/file/remove',
            params: {
                course_id  : null,
                chat_wizard_embedding_model_file_id: selectedModel?.id
            },
        }).then(response => {
            window.$notification({
                text: 'Question is deleted successfully!',
                variant: 'primary',
                position: 'center-top'
            })
            setLoader(false);
            setConfirmDeleteModal(false);
            getEmbedingModels();
        }).catch((error) => {
            if (error.response) {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                })
            }
        });
      }

    return (
        <main className="main-content h-100vh mt-0">
            <div
                className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-2 mt-2 transition-all duration-[.25s]">
                <div>
                    <div className="card mt-18">
                    <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                        <table className="is-hoverable w-full text-left">
                            <thead>
                            <tr>
                                <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    #
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Model
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {modelsList.length > 0 && modelsTable}
                            </tbody>
                        </table>
                    </div>
                </div>

                </div>
            </div>
            <Dialog
                open={uploadFileModal}
                onClose={()=> handleCloseAddFileModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                <h2 className="text-lg text-slate-700 dark:text-navy-100">
                        Upload file
                </h2>                
                <button
                   onClick={()=> handleCloseAddFileModal()}
                    className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4.5 w-4.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
                <div className="mt-4">
                    <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                        <span>File:</span>
                        <input className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" 
                            type="file" accept=".pdf,.doc,.docx" onChange={(e) => setModelFile(e.target.files[0])} />
                            <span className="text-error-focus">{errorsList.filter((name) => name.path === "modelFile")?.[0]?.errors?.[0]}</span>

                        </label>

                    </div>
                    </form>
                    <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                <button
                    className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                    onClick={(event) => handleCloseAddFileModal()}
                    >
                    Cancel
                </button>
                <button
                            onClick={handleUploadFile}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                >
                    Add
                </button>
            </div>
                </div>
        </Dialog>
        <Dialog
                open={confirmDeleteModal}
                onClose={()=> setConfirmDeleteModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                    alt="logo"
                />
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Delete
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to delete this file ?
                    </p>
                    <div className="space-x-2 text-center mt-4">
                        <button
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setConfirmDeleteModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleDeleteFile}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
        </main>
    );
}

export default WizardEmbededModels;