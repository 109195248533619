export const faqsList = [
    {
        question: "website_faq.question_1.question",
        answer: "website_faq.question_1.answer"
    },
    {
        question: "website_faq.question_2.question",
        answer: "website_faq.question_2.answer"
    },
    {
        question: "website_faq.question_3.question",
        answer: "website_faq.question_3.answer"
    },
    {
        question: "website_faq.question_4.question",
        answer: "website_faq.question_4.answer",
        type: "list"

    },
    {
        question: "website_faq.question_5.question",
        answer: "website_faq.question_5.answer",
        type: "list"
    },
    {
        question: "website_faq.question_6.question",
        answer: "website_faq.question_6.answer"
    },
    {
        question: "website_faq.question_7.question",
        answer: "website_faq.question_7.answer"
    },
];