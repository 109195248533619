import { faqsList } from "./faqList";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { useState } from "react";
import {useTranslation} from "react-i18next";

const FaqContainer = () => {
    const {t} = useTranslation();

    const [expanded, setExpanded] = useState(false);
    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };
    return(
            <div id="faq" className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-slate-300 text-gray-800 mb-8">
                    Frequently asked questions
                </h2>
                {faqsList.map((faq, index) => (
                    <Accordion expanded={expanded === index} onChange={handleChange(index)} key={index}>
                    <AccordionSummary
                        className={`${expanded === index ? "!bg-green-50 dark:!bg-navy-600 dark:text-slate-300" : "dark:bg-navy-200"}`}
                        expandIcon={<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 10L12 15L17 10" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        >
                        <h3 className="!font-semibold">{t(faq.question)}</h3>
                    </AccordionSummary>
                    <AccordionDetails className="!bg-green-50">
                        <div>
                            {faq.type === 'list' ? (
                            <ul>
                                {t(faq.answer).split('\n').map((item, itemIndex) => (
                                item ? <li className="list-disc ml-4" key={itemIndex}>{item.replace(/^\s*\d+\.\s*/, '')}</li> : null
                                ))}
                            </ul>
                            ) : (
                            <span>{t(faq.answer)}</span>
                            )}
                        </div>
                    </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        )
}
export default FaqContainer;